<template lang="pug">
include ../../../configs/template
div.text-left.row.pt-5
  div.col-sm-12.col-md-4.py-3
    +data-info('createDate', 'sailorDigitalDocument.created_at')
  div.col-sm-12.col-md-4.py-3
    +data-info('fullNameUkr', 'sailorDigitalDocument.sailor.full_name_ukr')
  div.col-sm-12.col-md-4.py-3
    +data-info('typeDoc', 'getDirectoryObject({ id:sailorDigitalDocument.type_document, value:"verificationDocumentTypes" })[nameLang]')
  div.col-sm-12.col-md-4.py-3
    +data-info('dateModified', 'sailorDigitalDocument.modified_at')
  div.col-sm-12.col-md-4.py-3
    +data-info('fullNameEn', 'sailorDigitalDocument.sailor.full_name_eng')
  div.col-sm-12.col-md-4.py-3
    +data-info('manager_full_name', 'sailorDigitalDocument.manager_full_name')
  div.col-sm-12.col-md-4.py-3
    +data-info('crewingName', 'sailorDigitalDocument.crewing_company')
  div.col-sm-12.col-md-4.py-3(v-if="sailorDigitalDocument.parent_document_id")
    +data-info-link('model-ServiceRecord', 'sailorDigitalDocument.parent_document_id', 'linOnServiceRecorBook')
  div.col-sm-12.col-md-4.py-3(v-if="sailorDigitalDocument.document_id")
    +data-info-link('documentId', 'sailorDigitalDocument.document_id', 'linkDocumentId')
  div.col-sm-12.col-md-6.py-3
    +data-info-status('status_document', 'getDirectoryObject({ id: sailorDigitalDocument.status_document, value: "statuses"})[nameLang]', 'getStatus(sailorDigitalDocument.status_document)')
  div(v-if="sailorDigitalDocument.comments?.length").col-sm-12.col-md-6
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    div(v-for="comment in sailorDigitalDocument.comments" :key="comment.id").ml-4
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getStatus } from '@/mixins/main'
import { TYPE_DOCUMENTS_CONSTANTS } from '@/configs/constants'
import { checkAccess } from '@/mixins/permissions'
export default {
  props: {
    sailorDigitalDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus
    }
  },
  watch: {
    sailorDigitalDocument: {
      handler () {},
      deep: true
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    linkDocumentId () {
      switch (+this.sailorDigitalDocument.type_document) {
        case TYPE_DOCUMENTS_CONSTANTS.MEDICAL_CERTIFICATE:
          return `/sailor/${this.sailorDigitalDocument.sailor.id}/medical/certificates/${this.sailorDigitalDocument.document_id}`
        case TYPE_DOCUMENTS_CONSTANTS.QUALIFICATION:
          return `/sailor/${this.sailorDigitalDocument.sailor.id}/qualification/documents/${this.sailorDigitalDocument.document_id}`
        case TYPE_DOCUMENTS_CONSTANTS.PROOF_OF_DIPLOMA:
          return { name: 'qualification-documents-proof-info', params: { id: this.sailorDigitalDocument.sailor.id, documentID: this.sailorDigitalDocument.document_id } }
        case TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD:
          return `/sailor/${this.sailorDigitalDocument.sailor.id}/experience/records/${this.sailorDigitalDocument.document_id}`
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD:
          return this.sailorDigitalDocument.parent_document_id
            ? `/sailor/${this.sailorDigitalDocument.sailor.id}/experience/records/${this.sailorDigitalDocument.parent_document_id}/line/${this.sailorDigitalDocument.document_id}`
            : `/sailor/${this.sailorDigitalDocument.sailor.id}/experience/records/`
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE:
          return `/sailor/${this.sailorDigitalDocument.sailor.id}/experience/reference/${this.sailorDigitalDocument.document_id}`
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_HISTORY:
          return `/sailor/${this.sailorDigitalDocument.sailor.id}/medical/certificates/${this.sailorDigitalDocument.document_id}`
        default: return ''
      }
    },
    linOnServiceRecorBook () {
      return `/sailor/${this.sailorDigitalDocument.sailor.id}/experience/records/${this.sailorDigitalDocument.parent_document_id}`
    }
  }
}
</script>
